import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logout, selectUser } from "../redux/slice/auth"
import { authModalOpen } from "../redux/slice/authModal"
import { useAppSelector } from "./index"
import { useLogoutApiMutation } from "../redux/api/auth"

export function useLogout(): () => void {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const [logoutApi] = useLogoutApiMutation()
  return () => {
    logoutApi().then(() => {
      window.Echo.leave(`privateUser.${user?.id}`)
      dispatch(logout())
      dispatch(authModalOpen({ flag: true }))
      navigate("/")
    })
  }
}
