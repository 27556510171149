import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {ICategory, IProject} from "../../types/content"
import { IChatMessage } from "../../types/chat"
import {authApi} from "../api/auth";
import {mainApi} from "../api/main";

type IState = {
  serviceList: ICategory[]
  serviceListLoading: boolean
  countOrdersWithoutReview: number
  countUnreadMessages: number
  countActiveCoordinations: number
  isHaveCoordinations: boolean
  allMessage: IChatMessage[]
  soloServiceIsOpened: boolean
  projects: IProject[]
  projectsModal: boolean
}

const initialState: IState = {
  serviceList: [],
  serviceListLoading: true,
  countOrdersWithoutReview: 0,
  countUnreadMessages: 0,
  countActiveCoordinations: 0,
  isHaveCoordinations: false,
  allMessage: [],
  soloServiceIsOpened: false,
  projects: [],
  projectsModal: false
}

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    changeServiceListLoading: (state: any, action: PayloadAction<boolean>) => {
      state.serviceListLoading = action.payload
    },
    updateServiceList: (state: any, action: PayloadAction<ICategory[]>) => {
      state.serviceList = action.payload
      state.serviceListLoading = false
    },
    updateCountOrdersWithoutReview: (state: IState, action: PayloadAction<number>) => {
      state.countOrdersWithoutReview = action?.payload
    },
    increaseCountOrdersWithoutReview: (state: IState) => {
      state.countOrdersWithoutReview += 1
    },
    decreaseCountOrdersWithoutReview: (state: IState) => {
      if (state.countOrdersWithoutReview === 0) return
      state.countOrdersWithoutReview -= 1
    },
    updateCountUnreadMessages: (state: IState, action: PayloadAction<number>) => {
      state.countUnreadMessages = action?.payload
    },
    decreaseCountUnreadMessages: (state: IState) => {
      state.countUnreadMessages -= 1
    },
    updateCountActiveCoordinations: (state: IState, action: PayloadAction<number>) => {
      state.countActiveCoordinations = action?.payload
    },
    setIsHaveCoordinations: (state: IState, action: PayloadAction<boolean>) => {
      state.isHaveCoordinations = action?.payload
    },
    setAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = action.payload
    },
    addAllMessage: (state: IState, action: PayloadAction<IChatMessage>) => {
      state.allMessage = [...state.allMessage.filter((f) => f.id !== action.payload.id), action.payload]
    },
    updateReadMessages: (state: any, action: PayloadAction<any>) => {
      state.allMessage = [
        ...state.allMessage.map((f: any) => (action?.payload?.includes(f.id) ? { ...f, is_readed: true } : f)),
      ]
    },
    setSoloServiceIsOpened: (state: any, action: PayloadAction<boolean>) => {
      state.soloServiceIsOpened = action.payload
    },
    setProjectsList: (state: any, action: PayloadAction<{ projects: IProject }>) => {
      state.projects = action.payload?.projects
    },
    projectsModalOpen: (state: any, action: PayloadAction<boolean>) => {
      state.projectsModal = action.payload
    },
    setActiveProject: (state: any, action: PayloadAction<{ id: string }>) => {
      state.projects = [...state.projects.map((projectsEl: IProject) => {
        if (projectsEl.id === action.payload.id) {
          return {
            ...projectsEl,
            isCurrent: true,
          }
        } else {
          return {
            ...projectsEl,
            isCurrent: false,
          }
        }
      })]
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(mainApi.endpoints.getProjects.matchFulfilled, (state, action) => {
      mainSlice.caseReducers.setProjectsList(state, action)
    })
    builder.addMatcher(mainApi.endpoints.setProjectUser.matchFulfilled, (state, action) => {
      mainSlice.caseReducers.setActiveProject(state, {
        ...action,
        payload: {
          id: action.meta.arg.originalArgs.project_id,
        }
      })
    })
  }
})

export const {
  changeServiceListLoading,
  updateServiceList,
  updateCountOrdersWithoutReview,
  updateCountUnreadMessages,
  decreaseCountUnreadMessages,
  decreaseCountOrdersWithoutReview,
  increaseCountOrdersWithoutReview,
  updateCountActiveCoordinations,
  setIsHaveCoordinations,
  setAllMessage,
  addAllMessage,
  updateReadMessages,
  setSoloServiceIsOpened,
  projectsModalOpen,
} = mainSlice.actions
export const countOrdersWithoutReview = (state: any) => state[mainSlice.name].countOrdersWithoutReview
export const countUnreadMessages = (state: any) => state[mainSlice.name].countUnreadMessages
export const isHaveCoordinations = (state: any) => state[mainSlice.name].isHaveCoordinations
export const countActiveCoordinations = (state: any) => state[mainSlice.name].countActiveCoordinations
export const selectProjects = (state: any) => state[mainSlice.name].projects
export const selectProjectsModal = (state: any) => state[mainSlice.name].projectsModal
