import {IProject} from "../../types/content";
import React, {useRef, useState} from "react";
import styles from './SelectProject.module.scss'
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import useWindowSize from "../../hooks/useWindowSize";
import {GetUserAvatar} from "../Assets/GetUserAvatar/GetUserAvatar";
import {ArrowDownIcon} from "../../icons/ArrowDownIcon";
import clsx from "clsx";
import {CheckIcon} from "../../icons/CheckIcon";
import {useSetProjectUserMutation} from "../../redux/api/main";


interface Props {
  projects: IProject[]
}

const SelectProject: React.FC<Props> = ({ projects }) => {
  const { isDesktop, size } = useWindowSize()
  const [open, setOpen] = useState<boolean>(false)
  const wrapRef = useRef<HTMLDivElement>(null)
  const [setProjectUser] = useSetProjectUserMutation()

  useOnClickOutside(wrapRef, () => {
    if (open && isDesktop) setOpen(false)
  })
  const currentProject = projects.filter((projectEl) => projectEl.isCurrent)[0]

  return (
    <div className={styles.projects} ref={wrapRef}>
      <div
        className={clsx(styles.current, open && styles['current--open'])}
        onClick={() => {setOpen((prev) => !prev)}}
      >
        <GetUserAvatar
          name={currentProject.name}
          avatar={currentProject.avatar_id}
          size={"sm"}
          className={styles.current__icon}
        />
        <div className={styles.current__name}>{currentProject.name}</div>
        <div className={styles.current__arrow}><ArrowDownIcon /></div>
      </div>
      {open && (
        <div className={styles.projects__list}>
          {projects.map((projectEl) => {
            return (
              <div
                className={clsx(styles.project, currentProject.id === projectEl.id && styles['project--selected'])}
                key={projectEl.id}
                onClick={() => {
                  if (projectEl.id !== currentProject.id) {
                    setProjectUser({project_id: projectEl.id})
                  }
                }}
              >
                <GetUserAvatar
                  name={projectEl.name}
                  avatar={projectEl.avatar_id}
                  size={"xs"}
                  className={styles.project__icon}
                />
                <div className={styles.project__name}>{projectEl.name}</div>
                {currentProject.id === projectEl.id && (
                  <div className={styles.project__check}>
                    <CheckIcon />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SelectProject