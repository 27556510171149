import { api } from "./index"
import { IChatInfo, IChatMessage } from "../../types/chat"

export const chatApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createChat: builder.mutation<IChatInfo, { type: "orders" | "users"; id: string }>({
      query: ({ type, id }) => ({
        url: `public/chat-messages/create/dialog/${type}/${id}`,
        method: "POST",
      }),
    }),
    getChatInfo: builder.query<IChatInfo, string>({
      query: (chatID) => ({
        url: `public/chat-messages/chats/${chatID}`,
        method: "GET",
      }),
    }),
    getUserChat: builder.query<IChatInfo, string>({
      query: (userID) => ({
        url: `public/chat-messages/find-user-chat`,
        method: "POST",
        body: { user_id: userID },
      }),
    }),
    getChatList: builder.query<
      { aDialogs: IChatInfo[]; bIsEnd: boolean; iCount: number },
      { limit?: number; offset?: number; search?: string } | void
    >({
      query: (data) => {
        let queryString = ""

        if (data) {
          for (const key in data) {
            if (!queryString) queryString = "?"
            if (queryString && queryString !== "?") queryString = queryString + "&"
            // @ts-ignore
            queryString = queryString + `${key}=${data[key]}`
          }
        }

        return {
          url: `public/chat-messages/chats${queryString}`,
          method: "GET",
        }
      },
    }),
    getChatMessages: builder.query<{ aMessages: IChatMessage[]; bIsEnd: boolean; iCount: number }, string>({
      query: (chatID) => ({
        url: `public/chat-messages/${chatID}`,
        method: "GET",
      }),
    }),
    sendChatMessage: builder.mutation<
      IChatMessage,
      { id: string; data: { id: string; text?: string; attachments?: string[]; photos?: string[] } }
    >({
      query: ({ id, data }) => ({
        url: `public/chat-messages/create/${id}`,
        method: "POST",
        body: data,
      }),
    }),
    markReadMessage: builder.mutation<void, { ids: string[] }>({
      query: (ids) => ({
        url: `public/chat-messages/mark-readed`,
        method: "POST",
        body: ids,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetChatListQuery,
  useLazyGetChatListQuery,
  useLazyGetUserChatQuery,
  useLazyGetChatMessagesQuery,
  useCreateChatMutation,
  useSendChatMessageMutation,
  useMarkReadMessageMutation,
} = chatApi
