import imageCompression, { Options } from "browser-image-compression"
import { toBase64 } from "./helpers"

interface OptionsProps extends Options {
  /*если на выходе нужен Blob*/
  blob?: boolean
  /*если на выходе нужен base64*/
  base64?: boolean
}

const defaultOptions: Options = {
  maxSizeMB: 1,
  // Use webworker for faster compression with the help of threads
  useWebWorker: true,
}

export async function compressImage(file: File, options: OptionsProps) {
  if (!file) return null
  try {
    const config = { ...defaultOptions, ...options }
    if (config?.maxSizeMB && config.maxSizeMB * 1048576 > file.size) {
      // если размер файла в пределах допустимого
      if (options?.blob) return await imageCompression(file, {})
      if (options?.base64) return await toBase64(file)
      return file
    }

    const compressedBlobFile = await imageCompression(file, config)
    if (options?.blob) return compressedBlobFile
    if (options?.base64) return await toBase64(compressedBlobFile)
    // compressedFile.lastModifiedDate = new Date()
    return new File([compressedBlobFile], file.name, {
      type: file.type,
      lastModified: Date.now(),
    })
  } catch (err) {
    console.error("err in compress image: ", err)
  }
}
