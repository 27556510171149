import { FC, ReactNode, useEffect, useState } from "react"
import useSetSettings from "../../hooks/useSetSettings"
import clsx from "clsx"
import Footer from "../Footer"
import Header from "../Header"
import useWindowSize from "../../hooks/useWindowSize"
import OrdersLine from "../OrdersLine/OrdersLine"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import OrdersLineLoader from "../OrdersLine/OrdersLineLoader"
import MobileMenu from "../MobileMenu/MobileMenu"
import MobileMoreModal from "../Modals/MobileMoreModal/MobileMoreModal"
import { getMobileMenuIsHidden, getOpenIsMoreModal, setOpenIsMoreModal } from "../../redux/slice/isMoreModal"
import LanguageModal from "../Modals/LanguageModal/LanguageModal"
import {
  increaseCountOrdersWithoutReview,
  isHaveCoordinations,
  setIsHaveCoordinations,
  updateCountActiveCoordinations,
  updateCountUnreadMessages,
} from "../../redux/slice/main"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import AuthModal2 from "../Modals/AuthModal2/AuthModal2"
import MessagesModal from "../Modals/MessagesModal/MessagesModal"
import { getMessagesModal, setMessagesModalIsOpen } from "../../redux/slice/modals"
import { getLangModalIsOpen } from "../../redux/slice/languageModal"
import { USER_ID_COOKIE } from "../../utils/constants"
import { getCookie } from "../../utils/cookies"
import OldBrowserModal from "../Modals/OldBrowserModal/OldBrowserModal"
import { useLazyGetCoordinationsQuery } from "../../redux/api/coordinations"
import { registerSW } from "../../utils/serviceWorkerRegistration"
import useChats from "../../hooks/useChats"
import useChangeStatusOrder from "../../hooks/useChangeStatusOrder"
import OpenChatBySearchParams from "../OpenChatBySearchParams/OpenChatBySearchParams"

interface Props {
  children: ReactNode
  mainClass?: string
  asideClass?: string
  isHeaderMobHidden?: boolean
  isAsideHidden?: boolean
  aside?: ReactNode
  isUserRequired?: boolean
  headerClickLogin?: () => void
  mainLoader?: ReactNode
}

const Layout: FC<Props> = ({
  children,
  mainClass,
  asideClass,
  isHeaderMobHidden,
  isAsideHidden,
  aside,
  isUserRequired = true,
  headerClickLogin,
  mainLoader,
}) => {
  const userIDCookie = getCookie(USER_ID_COOKIE)

  useSetSettings()
  useChats()
  useChangeStatusOrder()

  const user = useAppSelector(selectUser)
  const mobileMoreModalIsOpen = useAppSelector(getOpenIsMoreModal)
  const messagesModalIsOpen = useAppSelector(getMessagesModal)
  const mobileMenuIsHidden = useAppSelector(getMobileMenuIsHidden)
  const { openModal } = useSelector((state: any) => state.authModal)
  const langModalIsOpen = useAppSelector(getLangModalIsOpen)
  const isCoordinationsLine = useAppSelector(isHaveCoordinations)
  const hash = location.hash

  const [moreModalIsOpen, setMoreModalIsOpen] = useState(false)
  const [isOldBrowserOpen, setOldBrowserOpen] = useState<boolean>(false)

  const { isDesktop } = useWindowSize()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const [getCoordinations] = useLazyGetCoordinationsQuery()

  useEffect(() => {
    if (user)
      getCoordinations({ limit: 1 }).then((res) => {
        dispatch(setIsHaveCoordinations(!!res?.data?.iCount))
      })
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    dispatch(setOpenIsMoreModal(hash.includes("#mobileMenu")))
    setMoreModalIsOpen(Boolean(user?.id) && !isDesktop && mobileMoreModalIsOpen)
  }, [hash, isDesktop, user?.id, mobileMoreModalIsOpen])

  useEffect(() => {
    dispatch(setMessagesModalIsOpen(hash.includes("#messagesModal")))
  }, [hash])

  useEffect(() => {
    if (!user?.id) return

    // Запись в кэш изображений
    const imgs = document.getElementsByTagName("img")
    const imgsSrc: string[] = []
    // console.log(imgs);
    for (let i = 0; i < imgs.length; i++) {
      const src = imgs[i].getAttribute("src") ?? ""
      if (!imgsSrc.includes(src) && !src.includes("blob") && !src.includes("googleusercontent")) imgsSrc.push(src)
    }
    registerSW()
    if (typeof caches !== "undefined") {
      caches
        ?.open("my-cache-v1")
        ?.then(function (cache) {
          return cache.addAll(imgsSrc)
        })
        .catch((err) => {
          console.log(`caching error -> `, err)
        })
    }
    const channel = window?.Echo?.private(`privateUser.${user?.id}`)
    channel?.listen(".changePublicUnreadMessages", (event: { count: number }) => {
      if (event?.count === 0 || event?.count) {
        dispatch(updateCountUnreadMessages(event.count || 0))
      }
    })
    channel?.listen(".changePublicCountActiveApprovals", (event: { count: number }) => {
      dispatch(updateCountActiveCoordinations(event.count || 0))
      if (!isCoordinationsLine) dispatch(setIsHaveCoordinations(true))
    })
    //TODO
    // channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
    //   console.log("%c deleteMessageInDialog: ", "font-size: 16px; font-weight: 700; color: red; ", event, "")
    //   //Если сообщение еще не было прочитанным, то уменьшаем
    //   // dispatch(decreaseCountUnreadMessages())
    // })

    return () => {
      channel?.stopListening(`.changePublicUnreadMessages`)
      channel?.stopListening(`.changePublicCountActiveApprovals`)
    }
  }, [user?.id])

  useEffect(() => {
    const isIE = window.navigator.userAgent.indexOf("MSIE") > 0
    if (isIE && !sessionStorage.getItem("isIE")) {
      setOldBrowserOpen(true)
      sessionStorage.setItem("isIE", "true")
    }
  }, [])

  if (isOldBrowserOpen) return <OldBrowserModal isOpen={isOldBrowserOpen} setOpen={setOldBrowserOpen} />

  if (!userIDCookie && isUserRequired && !openModal) {
    return <AuthModal2 />
  }

  if (openModal && isUserRequired) return <AuthModal2 />

  return (
    <>
      <div className="wrapper">
        <Header isMobHidden={isHeaderMobHidden} headerClickLogin={headerClickLogin} />
        <main className={clsx(mainClass, !isDesktop && "layout__footer-padding")}>
          {isDesktop && !isAsideHidden && (
            <aside className={clsx("main-aside", asideClass)}>
              <div className={"main-aside__inner"}>
                {aside ? aside : user?.id ? <OrdersLine /> : <OrdersLineLoader />}
              </div>
            </aside>
          )}
          <div className={clsx("main-content", isAsideHidden && "main-content--full")}>
            {isUserRequired ? <>{user?.id ? children : mainLoader && isDesktop ? mainLoader : ""}</> : children}
          </div>
        </main>
        {isDesktop && <Footer />}
        {!isDesktop && !mobileMenuIsHidden && <MobileMenu />}
        {moreModalIsOpen && <MobileMoreModal />}
        {!isDesktop && langModalIsOpen && <LanguageModal isOpen={langModalIsOpen} />}
        {messagesModalIsOpen && (
          <MessagesModal
            open={messagesModalIsOpen}
            setOpen={(res) => {
              if (res !== undefined) dispatch(setMessagesModalIsOpen(res))
            }}
          />
        )}
      </div>
      <OpenChatBySearchParams />
    </>
  )
}

export default Layout
