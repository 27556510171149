import { FC, useCallback, useEffect, useMemo, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import OrdersList from "../OrdersList/OrdersList"
import { useTranslation } from "react-i18next"
import { useLazyPostServiceOrdersQuery } from "../../redux/api/content"
import { useLocation, useNavigate } from "react-router-dom"
import RatingModal from "../Modals/RatingModal/RatingModal"
import { useDispatch } from "react-redux"
import { clearMessageCountId, setNeedClearNewMessageCount, updateOrdersList } from "../../redux/slice/ordersList"
import { useAppSelector } from "../../hooks"
import Search from "../Search/Search"
import _debounce from "lodash/debounce"
import { IChatInfo, ILastMessage } from "../../types/chat"
import { IServiceOrderShort } from "../../types/content"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"
import { setMobileMenuIsHidden } from "../../redux/slice/isMoreModal"
import { selectUser } from "../../redux/slice/auth"
import { IServiceOrderWithReview } from "../../types/orderTypes"
import { Channel } from "laravel-echo"
import moment from "moment"

interface Props {
  layout?: "history" | "active"
}

const LIMIT_ORDERS = 7

const OrdersLayout: FC<Props> = ({ layout }) => {
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const [orderInfoModal, setOrderInfoModal] = useState<boolean>(false)
  const [isRatingModal, setIsRatingModal] = useState<boolean>(false)
  const [getActiveOrders, { data: requestsData, isLoading }] = useLazyPostServiceOrdersQuery()
  const [currentPage, setCurrentPage] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [ordersState, setOrdersState] = useState<{
    orders: IServiceOrderShort[]
    isEnd: boolean
  }>({
    orders: [],
    isEnd: false,
  })
  const [openedOrder, setOpenedOrder] = useState<string | undefined>(undefined)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [searchVal, setSearchVal] = useState<string>("")
  const [chatID, setChatID] = useState<string>("")
  const [openModalHash, setOpenModalHash] = useState(false)

  const clearMessageCountIdStr = useAppSelector(clearMessageCountId)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const hash = location.hash

  const orderClickFcn = (order: IServiceOrderShort) => {
    dispatch(setMobileMenuIsHidden(true))
    setOrderInfoModal(true)
    setOpenedOrder(order.id)

    setChatID(order.chat_dialog_id)
    navigate("#orderModal")
  }

  useEffect(() => {
    setOpenModalHash(hash.includes("#orderModal"))
  }, [hash])

  const closeOrderInfoModal = () => {
    setOrderInfoModal(false)
    navigate(location.pathname)
  }

  const ratingOpenFn = (id: string) => {
    setIsRatingModal(true)
    setOpenedOrder(id)
  }

  useEffect(() => {
    if (!user?.id || !ordersState.orders?.length) return

    const channel: Channel = window.Echo?.private(`privateUser.${user?.id}`)

    channel?.listen(".newMessageInDialog", (event: IChatInfo) => {
      setOrdersState((prev) => {
        const index = prev.orders.findIndex((i) => i.id === event.order_id)
        const cloneOrders = [...prev.orders]
        if ((index !== 0 && !index) || !cloneOrders[index] || (orderInfoModal && openedOrder === event.order_id)) {
          return prev
        }
        cloneOrders[index] = { ...cloneOrders[index], message_counts: event.unreaded_messages }
        return { orders: cloneOrders, isEnd: prev.isEnd }
      })
    })
    channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
      if (event.is_readed) return
      //Если сообщение еще не было прочитанным, то уменьшаем
      setOrdersState((prev) => {
        const index = prev.orders.findIndex((i) => i.chat_dialog_id === event.chat_dialog_id)
        const cloneOrders = [...prev.orders]
        if (
          (index !== 0 && !index) ||
          !cloneOrders[index] ||
          (orderInfoModal && openedOrder === cloneOrders[index].id)
        ) {
          return prev
        }
        cloneOrders[index] = {
          ...cloneOrders[index],
          message_counts:
            cloneOrders[index].message_counts > 0
              ? cloneOrders[index].message_counts - 1
              : cloneOrders[index].message_counts,
        }
        return { ...prev, orders: cloneOrders }
      })
    })

    return () => {
      // channel.stopListening(`.newMessageInDialog`)
      // channel.stopListening(`.deleteMessageInDialog`)
    }
  }, [layout, user, ordersState, orderInfoModal, openedOrder])

  useEffect(() => {
    if (!user?.id) return
    const channel: Channel = window.Echo?.private(`privateUser.${user?.id}`)

    channel?.listen(".changeStatusPublicOrder", (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      const newOrder: IServiceOrderShort = {
        id: event.id,
        chat_dialog_id: event.chat_dialog_id,
        name: event.name,
        created_at: event.created_at as any,
        message_counts: 0,
        hasReview: event.hasReview,
        count: event.count,
        number: event.number,
        path_name: event.path_name,
        photos: event.photos,
        status: event.status,
      }
      setOrdersState((prev) => {
        if ((event.status === 0 && layout === "active") || (event.status !== 0 && layout === "history")) {
          // если мы на странице Активные заявки и заявка стала активной
          // или если мы на странице История и заявка стала неактивной
          const isExist = prev.orders.some((order) => order.id === event.order_id)
          if (isExist) return prev
          // в зависимости от даты, вставить на нужное место в Orders
          if (prev.isEnd) {
            // если все orders уже загружены, то просто вставляем новый order в нужное место по дате
            return {
              ...prev,
              orders: [newOrder, ...prev.orders].sort((a, b) => {
                return moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
              }),
            }
          } else {
            // есть ли уже эта дата или дата До event.created_at.
            const isDateExist = prev.orders.some((order) => {
              return (
                moment(order.created_at).isSame(event.created_at, "day") ||
                moment(order.created_at).isBefore(event.created_at, "day")
              )
            })
            if (isDateExist) {
              return {
                ...prev,
                orders: [newOrder, ...prev.orders].sort((a, b) => {
                  return moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
                }),
              }
            }
            return prev
          }
        }
        if ((event.status === 0 && layout === "history") || (event.status !== 0 && layout === "active")) {
          // если страница История и заявка стала активной
          // или страница Активных заявок и заявка стала неактивной
          return {
            ...prev,
            orders: prev.orders.filter((order) => order.id !== event.id),
          }
        }
        return prev
      })
    })

    channel?.listen(".createPublicOrder", (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      const newOrder: IServiceOrderShort = {
        id: event.id,
        chat_dialog_id: event.chat_dialog_id,
        name: event.name,
        created_at: event.created_at as any,
        message_counts: 0,
        hasReview: event.hasReview,
        count: event.count,
        number: event.number,
        path_name: event.path_name,
        photos: event.photos,
        status: event.status,
      }
      setOrdersState((prev) => {
        if (event.status === 0 && layout === "active") {
          // если мы на странице Активные заявки и заявка стала активной
          const isExist = prev.orders.some((order) => order.id === event.order_id)
          if (isExist) return prev
          // в зависимости от даты, вставить на нужное место в Orders
          if (prev.isEnd) {
            // если все orders уже загружены, то просто вставляем новый order в нужное место по дате
            return {
              ...prev,
              orders: [newOrder, ...prev.orders].sort((a, b) => {
                return moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
              }),
            }
          } else {
            // есть ли уже эта дата или дата До event.created_at.
            const isDateExist = prev.orders.some((order) => {
              return (
                moment(order.created_at).isSame(event.created_at, "day") ||
                moment(order.created_at).isBefore(event.created_at, "day")
              )
            })
            if (isDateExist) {
              return {
                ...prev,
                orders: [newOrder, ...prev.orders].sort((a, b) => {
                  return moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
                }),
              }
            }
            return prev
          }
        }
        return prev
      })
    })
  }, [layout, user])

  useEffect(() => {
    // при открытии модалки-заявки, обнуляем кол-во прочитанных сообщений у самой заявки, т.к. чат по ней будет открыт и сообщения станут прочитанными
    if (!orderInfoModal || !openedOrder) return
    setOrdersState((prev) => {
      const index = prev.orders.findIndex((i) => i.id === openedOrder)
      const cloneOrders = [...prev.orders]
      if ((index !== 0 && !index) || !cloneOrders[index]) return prev
      cloneOrders[index] = { ...cloneOrders[index], message_counts: 0 }
      return { orders: cloneOrders, isEnd: prev.isEnd }
    })
  }, [orderInfoModal, openedOrder])

  useEffect(() => {
    if (isFirstRender) return
    if (!fetching || isLoading) return
    if (ordersState.isEnd) return
    getActiveOrders({ limit: LIMIT_ORDERS, offset: currentPage * LIMIT_ORDERS, isActive: layout === "active" })
      .unwrap()
      .then((res) => {
        setOrdersState((prev) => {
          const idsPrev = prev.orders.map((order) => order.id)
          const filteredNextOrders = res.data.aItems.filter((order) => {
            return !idsPrev.includes(order.id)
          })
          return {
            orders: [...prev.orders, ...filteredNextOrders],
            isEnd: res.data.bIsEnd,
          }
        })
        dispatch(updateOrdersList({ list: res.data.aItems }))
        setFetching(false)
        setCurrentPage((prev) => prev + 1)
      })
  }, [fetching, isLoading, ordersState.isEnd, currentPage, LIMIT_ORDERS, isFirstRender])

  useEffect(() => {
    getActiveOrders({ limit: LIMIT_ORDERS, offset: currentPage * LIMIT_ORDERS, isActive: layout === "active" })
      .unwrap()
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        })
        setOrdersState(() => {
          return {
            orders: [...res.data.aItems],
            isEnd: res.data.bIsEnd,
          }
        })
        dispatch(updateOrdersList({ list: res.data.aItems }))
        setIsFirstRender(false)
        setCurrentPage((prev) => prev + 1)
        checkHeightWindowForFetching()
      })
      .catch((err) => {
        if (err.status === 401) navigate("/")
        console.warn(err)
      })

    document.addEventListener("scroll", scrollHandler)

    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  // Если появляется айди (записываемое в модалке чата), то для него обнуляем счетчик непрочитанных сообщений
  useEffect(() => {
    if (clearMessageCountIdStr) {
      setOrdersState((prev) => {
        return {
          ...prev,
          orders: [...prev.orders.map((el) => (el.id === clearMessageCountIdStr ? { ...el, message_counts: 0 } : el))],
        }
      })
      dispatch(setNeedClearNewMessageCount(""))
    }
  }, [clearMessageCountIdStr])

  //Пока что закомментил для текста. Если все норм, то удалить потом
  // useEffect(() => {
  //   return () => {
  //     if (!isDesktop) {
  //       dispatch(setMobileMenuIsHidden(false))
  //       allowScroll(true)
  //     }
  //   }
  // })

  const checkHeightWindowForFetching = () => {
    const windowHeight = window.innerHeight
    const mainHeight = document.querySelector("main")?.offsetHeight

    if (mainHeight) {
      if (windowHeight >= mainHeight && !isLoading) {
        setFetching(true)
      }
    }
  }

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 80) {
      if (!isLoading) {
        setFetching(true)
      }
    }
  }

  // ф-я удаления из списка текущих заявок при отмене
  const delOrderFromList = (orderId: string) => {
    setOrdersState((prev) => {
      return {
        ...prev,
        orders: [...prev.orders.filter((el) => el.id !== orderId)],
      }
    })
  }

  const [ordersSearch, setOrdersSearch] = useState<string>("")
  const debounceFn = useCallback(
    _debounce((str: string) => {
      setOrdersSearch(str)
    }, 500),
    [],
  )
  useEffect(() => {
    debounceFn(searchVal)
  }, [searchVal])

  const ordersList = useMemo(() => {
    if (!ordersSearch) return ordersState.orders
    return ordersState.orders.filter(({ name }) => name?.toLowerCase().includes(ordersSearch.toLowerCase()))
  }, [ordersState.orders, ordersSearch])

  return (
    <>
      <TitleBack title={layout === "active" ? t("actRequests") : t("historyRequests")} />
      <Search searchVal={searchVal} setSearchVal={setSearchVal} />
      <OrdersList
        layout={layout}
        orders={isFirstRender && requestsData ? [...requestsData.data.aItems] : ordersList}
        clickFcn={orderClickFcn}
        ratingFn={ratingOpenFn}
        isLoading={isLoading}
        isFirstRender={isFirstRender}
        isLoadingAppend={fetching && !ordersState.isEnd}
      />
      {orderInfoModal && openedOrder && openModalHash && (
        <OrderInfoModal
          id={openedOrder}
          chatID={chatID}
          open={orderInfoModal}
          setOpen={setOrderInfoModal}
          delFromList={delOrderFromList}
          layout={layout}
          closeModalFunc={closeOrderInfoModal}
        />
      )}
      {openedOrder && isRatingModal && (
        <RatingModal
          orderId={openedOrder}
          open={isRatingModal}
          setOpen={setIsRatingModal}
          onSubmit={(id) => {
            if (!id) return
            const index = ordersState.orders.findIndex((i) => i.id === id)
            if (index !== 0 && !index) return
            setOrdersState((prev) => {
              const cloneOrders = [...prev.orders]
              cloneOrders[index] = { ...cloneOrders[index], hasReview: true }
              return {
                ...prev,
                orders: cloneOrders,
              }
            })
          }}
        />
      )}
    </>
  )
}

export default OrdersLayout
