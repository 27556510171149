import { Dispatch, FC, ReactNode, SetStateAction } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import styles from "./InfoModal.module.scss"
import clsx from "clsx"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  modalTitle?: string
  modalText?: string
  icon?: ReactNode
}

const InfoModal: FC<Props> = ({ open, setOpen, modalTitle, modalText, icon }) => {
  return (
    <ModalPortal isOpen={open} setIsOpen={setOpen} className={styles.modal}>
      {modalTitle && <h2 className={clsx("modal__title")}>{modalTitle}</h2>}
      <div className={styles["modal__content"]}>
        {icon}
        {modalText && <p className={styles.txt}>{modalText}</p>}
      </div>
    </ModalPortal>
  )
}

export default InfoModal
