import React from "react"
import styles from "./ProjectModal.module.scss"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { languageModalOpen } from "../../../redux/slice/languageModal"
import { LANG_LIST } from "../../../utils/constants"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import {projectsModalOpen, selectProjects} from "../../../redux/slice/main";
import {useLazyGetProjectsQuery, useSetProjectUserMutation} from "../../../redux/api/main";
import {IProject} from "../../../types/content";
import {useAppSelector} from "../../../hooks";
import {GetUserAvatar} from "../../Assets/GetUserAvatar/GetUserAvatar";

interface Props {
  isOpen: boolean
}

const ProjectModal: React.FC<Props> = ({ isOpen }) => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: `interface` })

  const projects = useAppSelector(selectProjects)
  const currentProject = projects.filter((projectEl: IProject) => projectEl.isCurrent)[0]
  const [setProjectUser] = useSetProjectUserMutation()
  const dispatch = useDispatch()
  // const { openModal } = useSelector((state: any) => state.languageModal)

  const setOpen = (flag: boolean) => {
    dispatch(projectsModalOpen(flag))
  }

  // Функция выбора языка
  const setProject = (projectId: string) => {
    setOpen(false)

    if (projectId !== currentProject.id) {
      setProjectUser({project_id: projectId})
    }
  }

  return (
    <ModalPortal
      isOpen={isOpen}
      setIsOpen={() => {
        dispatch(projectsModalOpen(!isOpen))
      }}
      onClose={() => {
        dispatch(setMobileMenuIsHidden(false))
        setOpen(false)
      }}
      className={"lang-modal"}
    >
      <div className={styles["lang-modal"]}>
        <div className={`modal-head`}>
          <div className={`modal-title`}>{t("interfaceProject")}</div>
          <div className={`modal-desc`}>{t("selectProjectDesc")}</div>
          {/* <img src="/img/logo_staq_sm.svg" alt="" /> */}
        </div>
        {/* <div className={`modal-text`}>{t("selectLangText")}</div> */}
        <div className={`lang-list`}>
          {projects.map((el: IProject, kk: number) => (
            <button key={kk} onClick={() => setProject(el.id)} className={styles["lang-btn"]}>
              <GetUserAvatar
                name={el.name}
                avatar={el.avatar_id}
                size={"xsm"}
              />
              <span>{el.name}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#4B5058" strokeWidth="2" />
              </svg>
            </button>
          ))}
        </div>
      </div>
    </ModalPortal>
  )
}

export default ProjectModal
