import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import { addAllMessage, selectChat, setAllMessage } from "../../../redux/slice/chat"
import { useDispatch } from "react-redux"
import Chat from "../../Chat/Chat"
import { useAppSelector } from "../../../hooks"
import clsx from "clsx"
import { getApiUrl } from "../../../utils/getApiUrl"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "./OrderCallbackModal"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import OrderDescription from "../../OrderDescription/OrderDescription"
import { useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import useWindowSize from "../../../hooks/useWindowSize"
import TitleBack from "../../TitleBack/TitleBack"
import RatingModal from "../RatingModal/RatingModal"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import { useLazyGetReviewsByOrderIDQuery } from "../../../redux/api/review"
import { selectUser } from "../../../redux/slice/auth"
import { IChatInfo, IChatMessage, ILastMessage } from "../../../types/chat"
import MessagesModalLoader from "../MessagesModal/MessagesModalLoader/MessagesModalLoader"
import { useLocation, useNavigate } from "react-router-dom"
import CoordinationFooter from "../../CoordinationFooter/CoordinationFooter"
import { IApprovalAssign, IServiceOrderWithReview } from "../../../types/orderTypes"
import { Channel } from "laravel-echo"
import { selectModals, setOrderModalOpen } from "../../../redux/slice/modals"
import styles from "./OrderInfoModal.module.scss"

interface Props {
  id: string
  chatID: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  delFromList?: (orderId: string) => void
  layout?: "history" | "active" | "coordination"
  modalSize?: "sm" | "lg"
  closeModalFunc?: () => void
}

const OrderInfoModal: FC<Props> = ({
  id,
  chatID,
  open,
  setOpen,
  delFromList = () => null,
  layout,
  modalSize,
  closeModalFunc,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const isFirstLoadMess = useRef<any>(false)
  const { allowScroll } = useScrollBlock()
  const { isDesktop } = useWindowSize()
  const user = useAppSelector(selectUser)
  const { allMessage } = useAppSelector(selectChat)
  const { isOrderModalOpen } = useAppSelector(selectModals)
  const navigate = useNavigate()
  const location = useLocation()

  // Количество непрочитанных сообщений
  const countMessageNoRead = allMessage?.filter((ms: any) => !ms?.is_readed)?.length

  const [getServiceOrder, { data: orderInfo }] = useLazyGetServiceOrderQuery()
  const [
    getReviewsByOrderID,
    { data: orderReview, isFetching: isFetchingRewiew, isUninitialized: isUninitializedRewiew },
  ] = useLazyGetReviewsByOrderIDQuery()
  const [getChatMessages] = useLazyGetChatMessagesQuery()
  const [openChat, setOpenChat] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)
  const [isRatingModal, setIsRatingModal] = useState<boolean>(false)
  const [dummyChats, setDummyChats] = useState<boolean>(false)
  const [isViewCoordinationFooter, setIsViewCoordinationFooter] = useState<boolean>(false)
  const isFirstRender = useRef(true)
  const [coordinationId, setCoordinationId] = useState<string>()
  const [currentLayout, setCurrentLayout] = useState<"history" | "active" | "coordination" | undefined>(layout)
  const [currentChatID, setCurrentChatID] = useState<string>(chatID)

  const closeModal = () => {
    dispatch(setMobileMenuIsHidden(false))
    setOpen(false)
    allowScroll(true)
    if (closeModalFunc) {
      closeModalFunc()
    }
  }

  useEffect(() => {
    if (!open || !id) return
    if (!isDesktop && isOrderModalOpen) return
    if (location.hash.includes("openChat")) return
    navigate({
      hash: "orderModal",
      search: `?order_id=${id}`,
    })

    return () => {
      navigate({
        hash: "",
        search: "",
      })
      dispatch(setOrderModalOpen(false))
    }
  }, [open, id, isDesktop, isOrderModalOpen])

  useEffect(() => {
    if (typeof window.Echo === "undefined" || !currentChatID) return
    const channel = window.Echo.join(`chats.${currentChatID}`)
    channel.listen(".client-addMessage", (event: any) => {
      dispatch(addAllMessage(event))
    })

    const channelPrivate = window.Echo.private(`privateUser.${user?.id}`)

    channelPrivate?.listen(".newMessageInDialog", (event: IChatInfo) => {
      const isExistMessage = allMessage.some((mes: IChatMessage) => mes.id === event?.last_message?.id)
      if (isExistMessage) return
      dispatch(addAllMessage(event.last_message))
    })

    channelPrivate?.listen(".changeMessageInDialog", (event: ILastMessage) => {
      dispatch(
        setAllMessage(
          allMessage.map((item: any) => {
            if (item.id === event.id) item = { ...item, ...event }
            return item
          }),
        ),
      )
    })
    channelPrivate?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
      dispatch(setAllMessage(allMessage.filter((item: any) => item.id !== event.id)))
    })

    return () => {
      if (allMessage?.length && !open) {
        window.Echo.leave(`chats.${currentChatID}`)
        channelPrivate.stopListening(`.newMessageInDialog`)
        channelPrivate.stopListening(`.changeMessageInDialog`)
        channelPrivate.stopListening(`.deleteMessageInDialog`)
      }
    }
  }, [open, currentChatID, user?.id, allMessage])

  useEffect(() => {
    if (!id) return
    const channel = window?.Echo?.private(`publicOrder.${id}`)

    channel?.listen(".publicOrderUpdateApproves", (event: { id: string }) => {
      getOrderFunc(event.id)
    })

    return () => {
      channel.stopListening(`.publicOrderUpdateApproves`)
      window.Echo?.leave(`publicOrder.${id}`)
    }
  }, [id])

  useEffect(() => {
    if (!user?.id || layout === "coordination" || typeof window.Echo === "undefined") return
    const channel: Channel = window?.Echo?.private(`privateUser.${user?.id}`)
    channel?.listen(".changeStatusPublicOrder", (event: IServiceOrderWithReview) => {
      setCurrentLayout(event.status === 0 ? "active" : "history")
    })
  }, [user?.id, layout])

  const getOrderFunc = async (id: string) => {
    if (!id) return
    await getServiceOrder(id)
      .then(async (resp) => {
        if (resp && Array.isArray(resp?.data) && !resp?.data?.length) {
          window.location.replace(window.location.origin + "/*/404")
        } else {
          setLoading(false)
          if (resp?.data?.chat_dialog_id) {
            setCurrentChatID(resp?.data?.chat_dialog_id)
            if (layout !== "coordination") {
              setCurrentLayout(resp.data.status === 0 ? "active" : "history")
            }
            await getChatMessages(resp.data.chat_dialog_id)
              .then((res: any) => {
                isFirstLoadMess.current = true
                dispatch(setAllMessage(res.data.aMessages))
                setLoadingChat(false)
              })
              .catch((er) => console.log(er.message))
          } else {
            setDummyChats(true)
          }
        }
      })
      .catch((err) => console.warn(err))
    isFirstRender.current = false
    getReviewsByOrderID(id)
  }

  useEffect(() => {
    if (!id) return
    getOrderFunc(id)
  }, [id])

  useEffect(() => {
    if (!isDesktop) {
      setOpenChat(location.hash.includes("#openChat"))
    }
  }, [location.hash])

  useEffect(() => {
    const currentApprovals = orderInfo?.approvals?.find((approval) => approval.status === "in_progress")

    const idCurrentUserApprove = currentApprovals?.assignees?.find((approval: IApprovalAssign) => {
      return approval.concordant.id === user.id && approval.status === "in_progress"
    })?.id

    setCoordinationId(idCurrentUserApprove)
    setIsViewCoordinationFooter(!!idCurrentUserApprove)
  }, [orderInfo, user])

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={() => {
          setOpen(!open)
          if (closeModalFunc) {
            closeModalFunc()
          }
        }}
        className={clsx(currentLayout && !isDesktop && styles["active-or-history-modal"], {
          [styles["opened-mobile-chat"]]: openChat && !isDesktop,
        })}
        header={
          <>
            {isDesktop ? (
              <>
                {!loading && orderInfo?.photos?.[0] && (
                  <img
                    className={styles.img}
                    src={getApiUrl() + "/public/photo/preview/" + orderInfo.photos[0]}
                    alt=""
                  />
                )}

                <h2 className={clsx("modal__title", styles.title, loading && "skeletonBlock")}>
                  {orderInfo?.name} {orderInfo?.count && orderInfo.count > 1 ? <span>x{orderInfo.count}</span> : ""}
                </h2>
              </>
            ) : (
              <>
                {loading ? (
                  <h2 className={clsx(styles.title, loading && "skeletonBlock")} />
                ) : (
                  <TitleBack
                    title={`${orderInfo?.name}
                      ${orderInfo?.count && orderInfo.count > 1 ? `x${orderInfo.count}` : ""}`}
                    onClick={() => {
                      if (openChat) {
                        setOpenChat(false)
                        navigate({
                          hash: location.hash?.replace("#openChat", ""),
                          search: `?order_id=${id}`,
                        })
                      } else {
                        navigate({
                          hash: "",
                          search: "",
                        })
                        closeModal()
                      }
                    }}
                    noLinkBack
                  />
                )}
              </>
            )}
          </>
        }
        isCloseBtn={isDesktop}
        bodyNoPadding
        size={modalSize}
      >
        <div className={styles.main}>
          {!isDesktop && openChat ? null : (
            <aside className={clsx(styles.aside, currentLayout === "coordination" && styles["aside-lg"])}>
              {orderInfo && !openChat && (
                <OrderDescription isLoading={loading} order={orderInfo} review={orderReview} layout={currentLayout} />
              )}

              {isDesktop ? (
                <>
                  <footer
                    className={clsx(
                      styles.footer,
                      isViewCoordinationFooter && styles["footer__painted"],
                      currentLayout && ["active", "history"].includes(currentLayout) && styles["column"],
                    )}
                  >
                    {isViewCoordinationFooter && (
                      <CoordinationFooter
                        coordinationId={coordinationId}
                        setIsViewCoordinationFooter={setIsViewCoordinationFooter}
                        getOrderFunc={() => getOrderFunc(id)}
                      />
                    )}
                    {currentLayout && ["active", "history"].includes(currentLayout) && (
                      <Button
                        txt={currentLayout === "active" ? t("cancel") : t("returnToWork")}
                        mode={"warning"}
                        size={"sm"}
                        onClick={() => setCanceledModal(true)}
                      />
                    )}
                  </footer>
                </>
              ) : (
                <div className={`${styles["btn-container"]}`}>
                  {isViewCoordinationFooter && (
                    <CoordinationFooter
                      coordinationId={coordinationId}
                      setIsViewCoordinationFooter={setIsViewCoordinationFooter}
                      getOrderFunc={() => getOrderFunc(id)}
                    />
                  )}
                  {(currentLayout === "active" || currentLayout === "history") && (
                    <div className={`${styles["btn-container__topTwoBtns"]}`}>
                      <Button
                        txt={currentLayout === "active" ? t("cancel") : t("returnToWork")}
                        mode={"warning"}
                        size={"sm"}
                        onClick={() => {
                          setCanceledModal(true)
                        }}
                      />
                      {!isUninitializedRewiew &&
                        !isFetchingRewiew &&
                        currentLayout === "history" &&
                        orderInfo?.status === 1 &&
                        (!orderReview || !orderReview.length) && (
                          <Button
                            txt={t("evaluate")}
                            size={"sm"}
                            onClick={() => {
                              setIsRatingModal(true)
                            }}
                          />
                        )}
                    </div>
                  )}

                  <div className={`${styles["btn-container__bottomTwoBtns"]}`}>
                    <Button
                      txt={t("back")}
                      type="button"
                      onClick={() => {
                        closeModal()
                      }}
                      className={`btn btn_grey ${styles["back-btn"]}`}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setOpenChat(true)
                        navigate({
                          hash: location.hash + "#openChat",
                          search: `?order_id=${id}`,
                        })
                      }}
                      className={`btn btn_grey ${styles["messenger-btn"]}`}
                    >
                      <img src="/img/chat.svg" alt="" />
                      {t("messages")}
                      {!!countMessageNoRead && (
                        <span className={`${styles.messagesNoReview}`}>{countMessageNoRead}</span>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </aside>
          )}

          {(isDesktop || openChat) && (
            <>
              {dummyChats ? (
                <div className={styles.dummyChat} />
              ) : loadingChat ? (
                <MessagesModalLoader />
              ) : (
                <Chat
                  chatID={currentChatID}
                  orderInfo={orderInfo}
                  isFirstLoadMess={isFirstLoadMess.current}
                  className={`${!isDesktop && styles["mobile-chat"]}`}
                  emptyTxt={t("emptyTxtChatOrder")}
                />
              )}
            </>
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && orderInfo?.id && (
        <OrderCallbackModal
          layout={currentLayout}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={orderInfo.id}
          delFromList={delFromList}
          callbackClose={() => closeModal()}
          className={!isDesktop ? "order-info-callback-modal" : ""}
        />
      )}
      {currentLayout === "history" && orderInfo?.id && (
        <RatingModal orderId={orderInfo?.id} open={isRatingModal} setOpen={setIsRatingModal} />
      )}
    </>
  )
}

export default OrderInfoModal
