// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectModal_lang-modal__L\\+xVF {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ProjectModal_lang-modal__L\\+xVF .ProjectModal_lang-btn__cHT0z {
  width: 100%;
  padding: 16px 8px 16px 32px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #EEE;
  margin-bottom: 8px;
}
.ProjectModal_lang-modal__L\\+xVF .ProjectModal_lang-btn__cHT0z:last-child {
  margin-bottom: 0;
}
.ProjectModal_lang-modal__L\\+xVF .ProjectModal_lang-btn__cHT0z img {
  max-width: 38px;
  border-radius: 4px;
}
.ProjectModal_lang-modal__L\\+xVF .ProjectModal_lang-btn__cHT0z span {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000;
  display: block;
  margin-left: 16px;
  text-align: left;
}
.ProjectModal_lang-modal__L\\+xVF .ProjectModal_lang-btn__cHT0z svg {
  margin-left: auto;
  fill: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ProjectModal/ProjectModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,WAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,eAAA;EACA,kBAAA;AAAN;AAGI;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AADN;AAII;EACE,iBAAA;EACA,UAAA;AAFN","sourcesContent":[".lang-modal {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  .lang-btn {\n    width: 100%;\n    padding: 16px 8px 16px 32px;\n    display: flex;\n    align-items: center;\n    border-radius: 12px;\n    background: #EEE;\n    margin-bottom: 8px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n\n    img {\n      max-width: 38px;\n      border-radius: 4px;\n    }\n\n    span {\n      font-size: 13px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 20px;\n      color: #000;\n      display: block;\n      margin-left: 16px;\n      text-align: left;\n    }\n\n    svg {\n      margin-left: auto;\n      fill: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lang-modal": `ProjectModal_lang-modal__L+xVF`,
	"lang-btn": `ProjectModal_lang-btn__cHT0z`
};
export default ___CSS_LOADER_EXPORT___;
