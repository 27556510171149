import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./OrderModal.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import OrderModalBlock from "./OrderModalBlock"
import CheckBoxRow from "../../Assets/CheckBoxRow/CheckBoxRow"
import { useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import moment from "moment"
import { useDropzone } from "react-dropzone"
import { SwiperOptions } from "swiper/types/swiper-options"
import { useTranslation } from "react-i18next"
import { IOrder } from "../../../types/orderTypes"
import { NumericFormat } from "react-number-format"
import { useLocation, useParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import SwipeLine from "../../Modal/SwipeLine"
import { declensionNumber, getFileIco, timeUnitsForLang, toBase64, url2File } from "../../../utils/helpers"
import OrderModalSkeleton from "./OrderModalSkeleton"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import FileDropzone from "../../Assets/FileDropzone/FileDropzone"
import { useAppSelector } from "../../../hooks"
import OrderModalPhotoBlock from "./OrderModalPhotoBlock"
import OrderModalCommonBlock from "./OrderModalCommonBlock"
import OptionsList from "./OptionsList"
import { getApiUrl } from "../../../utils/getApiUrl"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import TitleBack from "../../TitleBack/TitleBack"
import Button from "../../Assets/Button/Button"
import Label from "../../Assets/Label/Label"
import { useGetTechObjectQuery } from "../../../redux/api/main"
import { getLocale } from "../../../utils/getLocale"
import ArticlesMaybe from "../../ArticlesMaybe/ArticlesMaybe"
import _debounce from "lodash/debounce"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalInfo: IOrder
  setInitialValuesForm: React.Dispatch<React.SetStateAction<any>>
  setSelectObjModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  orderModalLoading?: boolean
  setValidateIds: any
}

const OrderModal: React.FC<Props> = ({
  open,
  setOpen,
  modalInfo,
  setInitialValuesForm,
  setSelectObjModal,
  isLoading,
  orderModalLoading,
  setValidateIds,
}) => {
  const swipedModal: any = useRef(null)
  const [isHaveTechObj, setIsHaveTechObj] = useState(false)
  const [isError, setIsError] = useState(false)
  const { values, submitForm, setFieldValue, isSubmitting, errors, isValidating } = useFormikContext<{
    [key: string]: any
  }>()
  const ref = useRef<HTMLDivElement>(null)
  const { object_id } = useParams()
  const localSavedValues = localStorage.getItem("savedOrder")

  const { data: techObjData } = useGetTechObjectQuery(object_id as string, { skip: !object_id })

  const firstTime = modalInfo.execution_time_from
  const secondTime = modalInfo.execution_time_to

  const object = useAppSelector((state) => state.object.technicalObject)
  // console.log(useFormikContext().values, useFormikContext().errors);

  const currentLang = getLocale()
  let currentTimeUnit =
    timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
      modalInfo.execution_time_units as "minutes" | "hours" | "days"
    ]

  if (currentLang === "ru") {
    if (modalInfo.execution_time_units === "hours") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["час", "часа", "часов"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["часа", "часов", "часов"])
      }
    }
    if (modalInfo.execution_time_units === "days") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["день", "дня", "дней"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(secondTime), ["дня", "дней", "дней"])
      }
    }
  }

  useEffect(() => {
    // Проскролливать при наличии ошибок и окончании сабмиттинга и валидатинга
    if (Object.keys(errors)?.length && !isValidating && !isSubmitting) {
      const errorsEls = ref.current?.querySelector(".error")
      errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [isSubmitting, ref])

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  // Инпут файл
  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
  }: any = useDropzone({
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setFieldValue(`${inputRef.current.name}`, [
        ...values[inputRef.current.name],
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ])
    },
  })

  useEffect(() => {
    // Проскролливать при наличии ошибок и окончании сабмиттинга и валидатинга
    if (Object.keys(errors)?.length && !isValidating && !isSubmitting) {
      const bodyModal = document.getElementById("orderModalContent")
      const errorsEls = bodyModal?.querySelector(".error")
      errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [isSubmitting])

  // Превьюшки
  const thumbs = (id: string) =>
    values[id]?.map((file: any) => (
      <div className={styles.photoBlock__preview} key={file.name}>
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <button
          type="button"
          onClick={() =>
            setFieldValue(
              id,
              values[id].filter((el: any) => el.path !== file.path),
            )
          }
          className={styles.photoBlock__close}
        >
          <img src="/img/close.svg" alt="" />
        </button>
      </div>
    ))

  const swiperParams: SwiperOptions = {
    slidesPerView: "auto",
    modules: [Pagination],
    pagination: {
      type: "bullets",
      clickable: true,
    },
  }

  const { pathname } = useLocation()
  const params = useParams()
  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")

  useEffect(() => {
    if (modalInfo?.technicalObjects?.length > 0) setIsHaveTechObj(true)
    createInitialValues(modalInfo?.options)
  }, [modalInfo])

  //Костыль добавления этих полей при открытии модалки
  useEffect(() => {
    if (open) {
      setFieldValue("service_id", modalInfo.id)
      setFieldValue("count", 1)
    }
  }, [open])

  const newInitVal: { [key: string]: any } = {}

  const debounceFn = useCallback(
    _debounce(async (values: any) => {
      if (localSavedValues) {
        const changeOrderFilesType = async () => {
          const newValues: { [key: string]: any } = {}

          for (const key in values) {
            // Проверка на поле с фото/файлами
            if (
              Array.isArray(values[key]) &&
              values[key].length > 0 &&
              (values[key][0].size || values[key][0].base64)
            ) {
              const newFiles: any[] = []

              await Promise.all(
                values[key].map(async (item: any) => {
                  const file = await toBase64(item)
                  newFiles.push({ file, name: item.name })
                }),
              )

              // Добавил ключ files, что бы из стореджа понимать что это файлы
              newValues[key] = { files: newFiles }
            } else {
              newValues[key] = values[key]
            }
          }
          return newValues
        }

        const changesValues = await changeOrderFilesType()

        const parsedLocalSavedValues: any[] = JSON.parse(localSavedValues)
        if (parsedLocalSavedValues.find((el) => el.service_id === values.service_id)) {
          const ordrIndex = parsedLocalSavedValues.findIndex((el) => el.service_id === values.service_id)
          parsedLocalSavedValues[ordrIndex] = changesValues
        } else {
          parsedLocalSavedValues.push(changesValues)

          if (parsedLocalSavedValues.length > 2) parsedLocalSavedValues.shift()
        }

        localStorage.setItem("savedOrder", JSON.stringify(parsedLocalSavedValues))
      }
    }, 2000),
    [],
  )

  useEffect(() => {
    if (!localSavedValues) {
      localStorage.setItem("savedOrder", JSON.stringify([]))
    }
    if (values.service_id) debounceFn(values)
  }, [values])

  const createInitialValues = async (options: any) => {
    const parsedFunc = async () => {
      const parsedValues = localSavedValues
        ? JSON.parse(localSavedValues).find((el: any) => el?.service_id === modalInfo.id)
        : undefined

      if (parsedValues)
        for (const key in parsedValues) {
          // Проверка на поле с фото/файлами
          if (parsedValues[key]?.files && parsedValues[key].files.length > 0) {
            const decodedFiles: any[] = []

            await Promise.all(
              parsedValues[key].files.map(async (item: any) => {
                const file = await url2File(item.file, `${item.name}`)
                decodedFiles.push(file)
              }),
            )

            parsedValues[key] = decodedFiles
          }
        }

      return parsedValues
    }

    const parsedLocalSavedValues: any = await parsedFunc()

    options &&
      options.forEach((option: any, i: number) => {
        if (option.type === "text") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ""
        if (option.type === "boolean") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "checkbox") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "number") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "datetime") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? undefined
        if (option.type === "list") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "photo") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "attachment") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "information")
          newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ["information", 0]
        if (option.type === "directory")
          newInitVal[`directory-${option.id}`] = parsedLocalSavedValues?.[`directory-${option.id}`] ?? []

        if (option.conditions.length > 0) {
          option.conditions.forEach((condition: any) => createInitialValues(condition.options))
        }
      })

    newInitVal.service_id = modalInfo.id
    newInitVal.count = 1

    // if (isHaveTechObj && !isObjectPage) newInitVal["technical_object_id"] = undefined
    // if (isHaveTechObj && isObjectPage) newInitVal["technical_object_id"] = params?.object_id

    const isHaveTechObj = modalInfo?.technicalObjects
    if (isHaveTechObj) {
      newInitVal["technical_object_id"] = isObjectPage ? object_id : undefined
    }

    setInitialValuesForm(newInitVal)
    // return newInitVal
  }

  const nextOrSubmitStep = () => {
    // const bodyModal = document.getElementById("orderModalContent")
    // const errorsEls = bodyModal?.querySelector(".error")
    // errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })

    // console.log(errorsEls, errors);

    submitForm()
    if (localSavedValues)
      localStorage.setItem(
        "savedOrder",
        JSON.stringify(JSON.parse(localSavedValues).filter((el: any) => el?.service_id !== modalInfo.id)),
      )
  }

  const setErrorHandler = (val: boolean) => {
    setIsError(val)
  }

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={clsx(styles.orderModal)}
      header={
        <>
          {!modalInfo.name || orderModalLoading ? (
            <div className={`selectBack ${styles.orderModal__sk4}`} />
          ) : (
            <>
              <TitleBack
                title={`${modalInfo?.name}`}
                onClick={() => {
                  setOpen(false)
                }}
                noLinkBack
              />
            </>
          )}
        </>
      }
      isCloseBtn={false}
      name={"OrderModal"}
    >
      <div
        className={clsx(
          styles.orderModal__body,
          orderModalLoading && styles.orderModal__body_load,
          !orderModalLoading && !modalInfo?.options?.length && styles.orderModal__roundedTop,
        )}
        ref={ref}
      >
        {orderModalLoading ? (
          <OrderModalSkeleton />
        ) : (
          <>
            {!!modalInfo?.description && (
              <div
                className={styles[`modal-text`]}
                dangerouslySetInnerHTML={{ __html: modalInfo?.description ?? "" }}
              />
            )}
            {(!!modalInfo?.technicalObjects?.length || isObjectPage) && (
              <div className={`${styles.orderModal__object}`}>
                <div className={`${styles.orderModal__objectIco}`}>
                  <img src="/img/object_ico.svg" alt="" />
                </div>
                <div className={`${styles.orderModal__objectText}`}>
                  <span>{t("selectedObject")}</span>
                  <p>
                    {techObjData?.name
                      ? techObjData.name
                      : isObjectPage
                      ? object.name
                      : values?.technical_object_id
                      ? modalInfo?.technicalObjects?.filter((el) => el.id === values.technical_object_id)?.[0]?.name ??
                        values.technical_object_name
                      : modalInfo?.technicalObjects?.[0]?.name}
                  </p>
                </div>
              </div>
            )}
            {modalInfo?.options?.length > 0 && (
              <OptionsList
                optionsItems={modalInfo?.options}
                setError={setErrorHandler}
                setValidateIds={setValidateIds}
              />
            )}
          </>
        )}

        {modalInfo.id && <ArticlesMaybe serviceID={modalInfo.id} />}
      </div>
      <div className={clsx(styles.orderModal__submitBlock, "submitBlock")}>
        {!orderModalLoading && (
          <>
            <div className={styles.orderModal__submitBlockHead}>
              <h5>{modalInfo?.name}</h5>
              {modalInfo.execution_time_from || modalInfo.execution_time_to ? (
                <Label
                  txt={(() => {
                    if (firstTime && secondTime) {
                      return `${firstTime}-${secondTime} ${currentTimeUnit}` as string
                    } else if (firstTime && !secondTime) {
                      return `${t("from")} ${firstTime} ${currentTimeUnit}` as string
                    } else if (!firstTime && secondTime) {
                      return `${t("to")} ${secondTime} ${currentTimeUnit}` as string
                    } else {
                      return ""
                    }
                  })()}
                  className={clsx(styles.orderModal__time)}
                  mode={"orange"}
                />
              ) : null}
            </div>

            <div className={styles.orderModal__submitBlockBottom}>
              {modalInfo?.is_multiorder ? (
                <div className={styles.orderModal__calc}>
                  <button
                    type="button"
                    className={styles.orderModal__calcPlus}
                    onClick={() => setFieldValue("count", values.count - 1)}
                    disabled={values.count < 2}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M12 8L4 8"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <div className={styles.orderModal__calcNum}>{values.count}</div>
                  <button
                    type="button"
                    className={styles.orderModal__calcPlus}
                    onClick={() => setFieldValue("count", values.count + 1)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M8 4L8 12"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 8L4 8"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              ) : null}
              {modalInfo?.service_action_name && (
                <Button
                  txt={modalInfo?.service_action_name}
                  onClick={nextOrSubmitStep}
                  disabled={isLoading}
                  isLoading={isLoading}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ModalPortal>
  )
}

export default OrderModal
